import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {fetchAllStores, fetchSingleStore, deleteStore, updateStore, createNewStore} from '../../api/storeApi';


// CRUD Thunks

// fetch all minimal products
export const fetchAllStoresThunk = createAsyncThunk(
    'products/fetchAllMinimalProducts',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchAllStores();
            console.log(response.data.data)
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "failed to fetch products");
        }
    }
);


// create new minimal product
export const createNewStoreThunk = createAsyncThunk(
    'store/createStore',
    async (productData, { rejectWithValue }) => {
        try {
            const response = await createNewStore(productData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message || "failed to create a minimal product");
        }
    }
);

// fetch single minimal product
export const fetchSingleStoreDetailsThunk = createAsyncThunk(
    'products/fetchSingleMinimalProduct',
    async (productId, { rejectWithValue }) => {
        try {
            const response = await fetchSingleStore(productId);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "failed to fetch minimal products")
        }
    }
);

// delete a minimal product
export const deleteStoreThunk = createAsyncThunk(
    'products/deleteMinimalProduct',
    async (productId, { rejectWithValue }) => {
        try {
            const response = await deleteStore(productId);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "failed to delete a minimal product");
        }
    }
);

// update a product
export const updateStoreThunk = createAsyncThunk(
    'products/updateminimalProduct',
    async ({productId, updatedData}, { rejectWithValue }) => {
        console.log(productId, updatedData)
        try {
            const response = await updateStore(productId, updatedData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "failed to update minimal product")
        }
    }
);


const storeSlice = createSlice({
    name: 'store',
    initialState: {
        stores: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Handle fetch
            .addCase(fetchAllStoresThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllStoresThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.stores = action.payload.data;
            })
            .addCase(fetchAllStoresThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // Handle create
            .addCase(createNewStoreThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(createNewStoreThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.stores.push(action.payload.data);
            })
            .addCase(createNewStoreThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload
            })

            // Handle update
            .addCase(updateStoreThunk.pending, (state) => {
                state.loading = true;
                console.log("updating...")
            })
            .addCase(updateStoreThunk.fulfilled, (state, action) => {
                console.log("updated...")

                state.loading = false;
                const index = state.stores.findIndex((item) => item._id === action.payload._id);
                if (index !== -1) {
                    state.stores[index] = action.payload;
                }
            })
            .addCase(updateStoreThunk.rejected, (state, action) => {
                console.log("failed...")
                state.loading = false;
                state.error = action.payload;
            })

            // Handle delete
            .addCase(deleteStoreThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteStoreThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.stores = state.stores.filter((item) => item._id !== action.payload.data._id);
            })
            .addCase(deleteStoreThunk.rejected, (state, action) => {
                console.log("failed to delete")
                state.loading = false;
                state.error = action.payload;
            });

    },
});


export default storeSlice.reducer;
