import {createModuleInstance} from "../api/axios";

const moduleType = 'store'

const storeInstance = createModuleInstance(moduleType);


// fetch all products
export const fetchAllStores = async () => {
    return storeInstance.get('/get-all');
}

// create new product
export const createNewStore = async (productData) => {
    return storeInstance.post('/create', productData);
}

// fetch single product
export const fetchSingleStore = async (id) => {
    return storeInstance.get(`/get-single/${id}`)
}

// update a product
export const updateStore = async (id, productData) => {
    return storeInstance.patch(`/update`,{params: {id: id}}, productData);
}

// delete a product
export const deleteStore = async (id) => {
    return storeInstance.delete(`/delete`, {params: {id: id}});
}
