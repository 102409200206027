import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchAllProducts, createNewProduct, fetchSingleProduct, deleteProduct, updateProduct, fetchProductsBySearch } from '../../api/productApi';

// CRUD thunks

// fetch all products
export const fetchAllProductsThunk = createAsyncThunk(
    'products/fetchAllProducts',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchAllProducts();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "failed to fetch products");
        }
    }
);

// create new product
export const createNewProductThunk = createAsyncThunk(
    'products/createProduct',
    async (productData, { rejectWithValue }) => {
        try {
            const response = await createNewProduct(productData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "failed to creat product");
        }
    }
);

// fetch single product
export const fetchSingleProductThunk = createAsyncThunk(
    'products/fetchSingleProduct',
    async (productId, { rejectWithValue }) => {
        try {
            const response = await fetchSingleProduct(productId);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "failed to fetch products")
        }
    }
);

// delete a product
export const deleteProductThunk = createAsyncThunk(
    'products/deleteProduct',
    async (productId, { rejectWithValue }) => {
        try {
            const response = await deleteProduct(productId);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "failed to delete product");
        }
    }
);

// update a product
export const updateProductThunk = createAsyncThunk(
    'products/updateProduct',
    async (data, { rejectWithValue }) => {
        try {
            const {productId, updatedProductObj} = data;
            const response = await updateProduct(productId, updatedProductObj);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "failed to update product")
        }
    }
);


// fetch products using search
export const fetchProductsUsingSearchQueryThunk = createAsyncThunk(
    'products/fetchUsingSearchQuery',
    async (searchQuery, { rejectWithValue }) => {
        try {
            const response = await fetchProductsBySearch(searchQuery);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "failed to update minimal product")
        }
    }
)

const productSlice = createSlice({
    name: 'completeProduct',
    initialState: {
        products: [],
        loading: false,
        error: null,
        currProduct: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Handle fetch
            .addCase(fetchAllProductsThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllProductsThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.products = action.payload.data;
            })
            .addCase(fetchAllProductsThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error.response?.data?.message;
            })

            // Handle create
            .addCase(createNewProductThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(createNewProductThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.products.push(action.payload);
                state.currProduct = action.payload.data;
            })
            .addCase(createNewProductThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // Handle update
            .addCase(updateProductThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateProductThunk.fulfilled, (state, action) => {
                state.loading = false;
                const index = state.products.findIndex((product) => product.id === action.payload.id);
                if (index !== -1) {
                    state.products[index] = action.payload;
                }
                state.currProduct = action.payload.data;
            })
            .addCase(updateProductThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // Handle delete
            .addCase(deleteProductThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteProductThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.products = state.products.filter((product) => product._id !== action.payload.data._id);
            })
            .addCase(deleteProductThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })


            // Handle get single
            .addCase(fetchSingleProductThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchSingleProductThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.currProduct = action.payload.data
            })
            .addCase(fetchSingleProductThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })


             // Handle fetch products using search
             .addCase(fetchProductsUsingSearchQueryThunk.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchProductsUsingSearchQueryThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.products = action.payload.data.products;
                console.log(action.payload)
            })
            .addCase(fetchProductsUsingSearchQueryThunk.rejected, (state, action) => {
                state.loading = false;
                console.log(action.payload)
                state.error = action.payload;
            });

    },
});


export default productSlice.reducer;