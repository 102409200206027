import axios from "axios";
import { createModuleInstance } from "./axios";
import { data } from "autoprefixer";

const moduleType = 'product'
// const APP_URI = 'http://localhost:3001/api/v1/products'
const productInstance = createModuleInstance(moduleType);


// fetch all products
export const fetchAllProducts = async () => {
    return productInstance.get('/get-all');
}

// create new product
export const createNewProduct = async (productData) => {
    return productInstance.post('/create', productData);
}

// fetch single product
export const fetchSingleProduct = async (id) => {
    return productInstance.get(`/get-single`, {params: {id: id}})
}

// update a product
export const updateProduct = async (id, productData) => {
    return productInstance.put(`/update`, {data: productData}, {params: {id: id}});
}

// delete a product
export const deleteProduct = async (id) => {
    return productInstance.delete(`/delete`, {params: {id: id}});
}

// fetch products using search query
export const fetchProductsBySearch = async (searchQuery) => {
    return productInstance.get(`/searchProducts`, {params: {searchedKey: searchQuery}});
}
