import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {login, logout, verifyToken} from '../../api/authApi'



//login
export const loginThunk = createAsyncThunk(
    'auth/login',
    async (data, { rejectWithValue }) => {
        try {
            const response = await login(data);
            console.log(response.data)
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || "failed to login");
        }
    }
);

// logout
export const logoutThunk = createAsyncThunk(
    'auth/logout',
    async ( data, {rejectWithValue}) => {
        try{
            const response = await logout(data);
            return response.data;
        }
        catch(error){
            return rejectWithValue(error.response?.data?.message || "failed to logout");
        }
    }
)

// verify-token
export const verifyTokenThunk = createAsyncThunk(
    'auth/logout',
    async ( _, {rejectWithValue}) => {
        try{
            const response = await verifyToken();
            return response.data;
        }
        catch(error){
            return rejectWithValue(error.response?.data?.message || "failed to verify");
        }
    }
)

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        authState: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loginThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.authState = action.payload.data;
            })
            .addCase(loginThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(logoutThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(logoutThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.authState = null;
            })
            .addCase(logoutThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error?.response?.data?.message || "failed to logout";
            })

    },
});

export default authSlice.reducer;
